import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    IntroQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../components/InnerPage';

const TemplatePage = ({ data }) => (
    <InnerPage title="Gott und der Mensch" description="">
        <IntroHolder
            title="Gott und der Mensch"
            subtitle="Einheit, Fortschreitende Offenbarung und Kultur als Prozess"
            picture={data.introImage}
            breadcrumbs={{
                parent: 'Woran Bahá’í glauben',
                'current-item': 'Gott und der Mensch',
            }}
        >
            <IntroQuote href="/woran-bahai-glauben/gott-und-der-mensch/zitate">
                Alles Erschaffene im ganzen All ist nur ein Tor zu Seiner
                Erkenntnis, ein Zeichen Seiner Herr­schaft, eine Offenbarung
                Seiner Namen, ein Sinnbild Sei­ner Erhabenheit, ein Beweis
                Seiner Macht, ein Zugang zu Seinem geraden Pfade.
            </IntroQuote>
        </IntroHolder>
        <TwoColumns>
            <div id="content">
                <p>
                    Gott ist gemäß den Bahá’í-Schriften Seinem Wesen der
                    menschlichen Erkenntnis nicht zugänglich. Der Mensch ist
                    Geschöpf, und das Geschöpf vermag seinen Schöpfer nicht zu
                    erkennen.
                </p>
                <div>
                    <p>
                        Obwohl das Wesen Gottes dem Menschen verborgen bleibt,
                        kann er durch seine Sinne und geistig-spirituellen
                        Kräfte Einblick in göttliche Eigenschaften erlangen.
                        Diese zeigen sich als Zeichen Gottes überall in der
                        Schöpfung, so auch in der Natur oder in den
                        Eigenschaften von Menschen, wenngleich in unvollkommener
                        Weise. Wir finden Sie auch in den{' '}
                        <Link to="/woran-bahai-glauben/gott-und-der-mensch/fortschreitende-offenbarung/">
                            Gottesoffenbarern
                        </Link>
                        , die für uns wie reine Spiegel der göttlichen
                        Wirklichkeit sind.
                    </p>
                </div>
                <div>
                    <p>
                        Es sind diese besonderen Persönlichkeiten wie Moses,
                        Buddha, Jesus Christus, Muhammad, Báb und Bahá’u’lláh,
                        die den Menschen immer wieder mit dem Willen Gottes
                        vertraut machen. Sie verfügen als Manifestationen Gottes
                        über vollkommene Eigenschaften und sind die{' '}
                        <Link to="/woran-bahai-glauben/gott-und-der-mensch/fortschreitende-offenbarung/">
                            universellen Erzieher
                        </Link>{' '}
                        der Menschheit.
                    </p>
                </div>
                <div>
                    <p>
                        Die Menschheit ist über Jahrtausende hinweg durch viele
                        Prüfungen und Krisen immer über sich selbst hinaus
                        gewachsen. Sie hat Grenzen überwunden, die für
                        unüberwindbar galten. Jetzt steht sie an der Schwelle
                        ihrer größten Herausforderung, die heute noch
                        bestehenden großen Hindernisse auf dem Weg zur Einheit
                        der Menschheit zu überwinden.
                    </p>
                </div>
                <div>
                    <p>
                        Nach den Lehren Bahá’u’lláhs ist der Mensch erschaffen
                        worden, um eine ständig{' '}
                        <Link to="/woran-bahai-glauben/gott-und-der-mensch/fortschreitende-kultur/">
                            voranschreitende Kultur
                        </Link>{' '}
                        hervorzubringen. Aus dieser Überzeugung heraus denken
                        Bahá’í mit Freunden und Bekannten ihres Umfeldes über
                        Wege und Möglichkeiten nach, wie sie den Wandel der
                        Gesellschaft hin zur Einheit in der Vielfalt{' '}
                        <Link to="/gemeinsames-handeln/">
                            aktiv mitgestalten
                        </Link>{' '}
                        können.
                    </p>
                </div>
                <Reference>
                    <p>1. Bahá&#8217;u&#8217;lláh, Ährenlese 82:5</p>
                </Reference>
            </div>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default TemplatePage;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: { eq: "gott-und-der-mensch-feature.jpg" }
        ) {
            ...fluidImage
        }
    }
`;
